import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import logo from './img/logo.jpeg'

function BasicExample() {
    const [expanded, setExpanded] = useState(false);

    const handleNavItemClick = () => {
        setExpanded(false);
    };

    return (
        <Navbar expand="lg" expanded={expanded} className="navigation">
            <Container>
                <Navbar.Brand as={Link} to="/" onClick={handleNavItemClick}>
                    <img src={logo} />
                </Navbar.Brand>
                <Navbar.Toggle
                    aria-controls="navbarSupportedContent"
                    onClick={() => setExpanded(!expanded)}
                />
                <Navbar.Collapse id="navbarSupportedContent">

                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/" className="color" onClick={handleNavItemClick}>मुख्य पृष्ठ</Nav.Link>

                        <Nav.Link as={Link} to="/About" className="color" onClick={handleNavItemClick}>हमारे बारे में</Nav.Link>

                        <Nav.Link as={Link} to="/women-education" className="color" onClick={handleNavItemClick}>महिला शिक्षा </Nav.Link>

                
                        <Nav.Link as={Link} to="/domestic-violence" className="color" onClick={handleNavItemClick}>घरेलू हिंसा  समाधान </Nav.Link>

                        <Nav.Link as={Link} to="/women-placement" className="color" onClick={handleNavItemClick}>प्लेसमेंट मार्गदर्शन</Nav.Link>

                        <Nav.Link as={Link} to="/Mahila" className="color" onClick={handleNavItemClick}>महिला सुरक्षा </Nav.Link>

                        <Nav.Link as={Link} to="/Kausal" className="color" onClick={handleNavItemClick}>कौशल  विकास  </Nav.Link>

                        <Nav.Link as={Link} to="/gallery" className="color" onClick={handleNavItemClick}>तस्वीरें</Nav.Link>

                        <Nav.Link as={Link} to="/Contact" className="color" onClick={handleNavItemClick}>हमारा संपर्क </Nav.Link>
                        

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default BasicExample;
