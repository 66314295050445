import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const ContactPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const message = `Hello, I would like to contact you.\n\nName: ${name}\nEmail: ${email}\nContact: ${contact}\nAddress: ${address}`;
    const whatsappNumber = "+919415495322";
    const encodedMessage = encodeURIComponent(message);
    window.open(`https://wa.me/${whatsappNumber}?text=${encodedMessage}`, "_blank");
  };

  return (
    <div className="container mt-5">
      <div className="row">
        {/* Google Map Section */}
        <div className="col-md-6 mb-4">
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3558.724887541626!2d80.9508493754384!3d26.880480476666463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDUyJzQ5LjciTiA4MMKwNTcnMTIuMyJF!5e0!3m2!1sen!2sin!4v1730908626419!5m2!1sen!2sin"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

        {/* Contact Form Section */}
        <div className="col-md-6">
          
          <form onSubmit={handleSubmit} className="p-4 border rounded shadow">
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name | नाम
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="form-control"
                placeholder="Enter your name | अपना नाम दर्ज करें"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email | ईमेल
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="form-control"
                placeholder="Enter your email | अपना ईमेल दर्ज करें"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="contact" className="form-label">
                Contact | संपर्क नंबर
              </label>
              <input
                type="tel"
                id="contact"
                name="contact"
                className="form-control"
                placeholder="Enter your contact number | अपना संपर्क नंबर दर्ज करें"
                value={contact}
                onChange={(e) => setContact(e.target.value)}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="address" className="form-label">
                Address | पता
              </label>
              <textarea
                id="address"
                name="address"
                className="form-control"
                placeholder="Enter your address | अपना पता दर्ज करें"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              ></textarea>
            </div>

            <button type="submit" className="btn btn-primary w-100">
              Submit | सबमिट करें
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
