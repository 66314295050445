import React, { useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import Footer from '../Components/Footer';

import skil1 from '../shakti/skill1.jpg';
import skil2 from '../shakti/skill2.jpg';
import skil3 from '../shakti/skill3.jpg';
import skil4 from '../shakti/skill4.jpg';
import skil5 from '../shakti/skill5.jpg';

const Kausal = () => {
    const [activePage, setActivePage] = useState(1);
    const totalPages = 4;

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    let items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === activePage}
                onClick={() => handlePageChange(number)}
            >
                {number}
            </Pagination.Item>
        );
    }

    return (
        <>
            <div className="Heading">
                Home / महिला कौशल विकास
            </div>

            {activePage === 1 && (
                <div className="naari">
                    <h1>Wake Foundation</h1>

                    <h2>महिला कौशल विकास मिशन</h2>
                    <p>महिलाओं को आत्मनिर्भर बनाने और उन्हें रोजगारपरक कौशल से सशक्त करने के लिए राज्य सरकार ने महिला कौशल विकास मिशन की शुरुआत की है।</p>
                    <p>इस मिशन के तहत, महिलाओं को निःशुल्क कौशल प्रशिक्षण प्रदान किया जाता है। प्रशिक्षण के साथ ही उन्हें रोजगार के लिए तैयार किया जाता है।</p>
                    <p>महिला कौशल विकास मिशन का उद्देश्य ग्रामीण और शहरी महिलाओं के लिए नई संभावनाओं के द्वार खोलना है।</p>
                    <img src={skil4} alt="Skill Training" />

                    <p>1. महिलाओं के लिए विशेष प्रशिक्षण कार्यक्रम।</p>
                    <p>2. स्थानीय उद्योगों के साथ साझेदारी कर रोजगार सुनिश्चित करना।</p>
                    <p>3. महिलाओं को तकनीकी और व्यावसायिक कौशल में प्रशिक्षित करना।</p>
                    <p>4. वित्तीय सहयोग के लिए विशेष राज्य निधि।</p>
                </div>
            )}

            {activePage === 2 && (
                <div className="naari">
                    <p>महिलाओं को उद्यमशीलता के लिए प्रेरित करने हेतु विशेष प्रशिक्षण कार्यक्रम आयोजित किए जा रहे हैं।</p>
                    <p>महिलाएं अपने रुचि अनुसार कोर्स चुन सकती हैं और उन्हें प्रैक्टिकल अनुभव के साथ गुणवत्तापूर्ण शिक्षा प्रदान की जाती है।</p>
                    <img src={skil1} alt="Skill Training" />
                    <ul>
                        <li>महिलाओं को आर्थिक स्वतंत्रता दिलाने के लिए प्रशिक्षण।</li>
                        <li>स्थानीय और वैश्विक स्तर पर रोजगार के अवसर।</li>
                        <li>महिलाओं के लिए डिजिटल और व्यावसायिक कौशल विकास।</li>
                        <li>विशेष उद्योग क्षेत्रों में प्रशिक्षण और प्लेसमेंट।</li>
                    </ul>
                    <p>महिलाओं की प्रगति के लिए जारी।</p>
                </div>
            )}

            {activePage === 3 && (
                <div className="naari">
                    <p>प्रशिक्षण के दौरान महिलाओं को आर्थिक सहायता भी प्रदान की जाती है।</p>
                    <p>महिलाओं को नजदीकी कौशल विकास केंद्र पर आवेदन करना होता है।</p>
                    <img src={skil2} alt="Skill Training" />
                    <ul>
                        <li>निःशुल्क आवेदन और प्रशिक्षण प्रक्रिया।</li>
                        <li>प्रशिक्षण के बाद नौकरी की गारंटी।</li>
                        <li>प्रशिक्षण के दौरान आर्थिक सहयोग।</li>
                    </ul>
                    <p>महिलाओं को उच्च गुणवत्ता के साथ प्रशिक्षण प्रदान कर उनके जीवन को बेहतर बनाने का लक्ष्य।</p>
                </div>
            )}

            {activePage === 4 && (
                <div className="naari">
                    <h2>महिला कृषि उपकरण योजना 2023</h2>
                    <p>महिलाओं को खेती में सशक्त बनाने और उनके लिए उन्नत कृषि उपकरण उपलब्ध कराने की पहल की गई है।</p>
                    <img src={skil3} alt="Agriculture Equipment" />
                    <h3>योजना के उद्देश्य</h3>
                    <p>महिलाओं को खेती के आधुनिक तरीकों से परिचित कराना और उन्हें आर्थिक रूप से आत्मनिर्भर बनाना।</p>
                    <p>महिला किसानों को तकनीकी उपकरणों का प्रशिक्षण और सब्सिडी के माध्यम से आर्थिक मदद।</p>
                    <p>महिलाओं की सहभागिता बढ़ाने के लिए विशेष योजना।</p>
                    {/* <img src={skil5} alt="Agriculture Scheme" /> */}
                    <p>महिला सशक्तिकरण की दिशा में एक कदम।</p>
                </div>
            )}

            <div className="d-flex justify-content-center my-4 pagination-container">
                <Pagination size="lg">{items}</Pagination>
            </div>

            <Footer />
        </>
    );
};

export default Kausal;
