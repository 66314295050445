import React from 'react';
import Footer from '../Components/Footer';

const updateImages = [];
const update2Images = [];
const newhts = [];

// Import images dynamically
for (let i = 1; i <= 28; i++) {
  updateImages.push(require(`../Components/Gallery/gal (${i}).jpeg`));
  update2Images.push(require(`../Components/Gallery/new (${i}).jpeg`));
}


// Import images dynamically
for (let i = 1; i <= 64; i++) {
  newhts.push(require(`../shakti/hts (${i}).jpeg`));

}


// Combine both arrays
const images = [...updateImages, ...update2Images,...newhts ];

const Gallery = () => {
  return (
    <>
      <div className="Heading">
        Home / Gallery
      </div>

      <div className="gallery-container">
        <div className="gallery-grid">
          {images.map((image, index) => (
            <div key={index} className="gallery-item">
              <img src={image} alt={`Gallery image ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Gallery;
