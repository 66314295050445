import React from 'react'
import Footer from '../Components/Footer'
import about from '../Components/Gallery/new (23).jpeg'

const About = () => {
    return (
        <>



            <div className='Heading'>
                Home / About
            </div>




            <div className='sections'>

                <div className='section2'>
                    <img src={about} />


                    <a href="Tel:+91-9415495322">
                        <button class="contact-button">Contact Us | हमारा संपर्क</button>
                    </a>


                </div>


                <div className='section2'>
                    <h1>वेक फाउंडेशन में आपका हार्दिक स्वागत है</h1>

                    <h2>सशक्त नारी, सशक्त समाज"</h2>
                    <p>हमारा लक्ष्य महिलाओं के जीवन में बदलाव लाकर उन्हें आत्मनिर्भर और सशक्त बनाना है। वेक फाउंडेशन समर्पित है महिलाओं के सर्वांगीण विकास के लिए।</p>


                    <h2>हमारे प्रमुख प्रयास:</h2>

                    <h3>🌸 महिला सशक्तिकरण:</h3>

                    <p>महिलाओं को उनके अधिकारों, आत्मसम्मान, और नेतृत्व क्षमता के प्रति जागरूक करना।</p>

                    <h3>🌸 महिला स्वास्थ्य:</h3>

                    <p>स्वास्थ्य शिविर, जागरूकता कार्यक्रम और मेडिकल सेवाओं के माध्यम से महिलाओं को स्वस्थ जीवन जीने के लिए प्रेरित करना।</p>

                    <h3>🌸 महिला शिक्षा:</h3>

                    <p>शिक्षा हर सपने की कुंजी है। हम महिलाओं को गुणवत्तापूर्ण शिक्षा प्रदान कर उनके भविष्य को उज्ज्वल बनाते हैं।</p>

                    <h3>🌸 रोजगार मार्गदर्शन:</h3>
                    <p>महिलाओं को रोजगार के नए अवसरों से जोड़ना और उनके करियर निर्माण में मदद करना।</p>

                   
                </div>



            </div>



            <Footer />
        </>
    )
}

export default About
