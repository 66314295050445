import React from 'react';
import Footer from '../Components/Footer';

const WomenPlacementGuidance = () => {
    return (
        <>


            <div className='Heading'>
                Home / महिला प्लेसमेंट मार्गदर्शन
            </div>


            <div className="naari">

                <p>महिलाओं के लिए रोजगार के अवसर बढ़ाने के लिए प्लेसमेंट मार्गदर्शन महत्वपूर्ण है।</p>
                <p>यह कार्यक्रम महिलाओं को सही नौकरी ढूंढने और उनकी क्षमताओं का विकास करने में मदद करता है।</p>
                <ul>
                    <li>रोजगार मेलों का आयोजन।</li>
                    <li>महिलाओं के लिए विशेष नौकरी पोर्टल।</li>
                    <li>व्यावसायिक कौशल प्रशिक्षण।</li>
                    <li>स्थानीय और वैश्विक स्तर पर नौकरी के अवसर।</li>
                </ul>
                <p>महिलाओं को उनके कार्यक्षेत्र में सफलता प्राप्त करने के लिए मार्गदर्शन और सहायता प्रदान करना, इस कार्यक्रम का मुख्य उद्देश्य है।</p>
            </div>

            <Footer />

        </>
    );
};

export default WomenPlacementGuidance;
