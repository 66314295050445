import React from 'react';

import ms1 from '../Components/Gallery/gal (3).jpeg';
import ms2 from '../Components/Gallery/gal (17).jpeg';
import ms3 from '../Components/Gallery/new (12).jpeg';
import ms4 from '../Components/Gallery/gal (9).jpeg';
import ms5 from '../Components/Gallery/new (22).jpeg';
import ms6 from '../Components/Gallery/new (18).jpeg';

const Images = () => {
    return (
        <div className="container py-5">
            <h2 className="text-center mb-4">Our Blog</h2>
            <div className="row g-4">
                <div className="col-12 col-sm-6 col-md-4">
                    <img src={ms1} alt="Image 1" className="img-fluid rounded shadow" />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <img src={ms2} alt="Image 2" className="img-fluid rounded shadow" />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <img src={ms3} alt="Image 3" className="img-fluid rounded shadow" />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <img src={ms4} alt="Image 4" className="img-fluid rounded shadow" />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <img src={ms5} alt="Image 5" className="img-fluid rounded shadow" />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <img src={ms6} alt="Image 6" className="img-fluid rounded shadow" />
                </div>
            </div>
        </div>
    );
};

export default Images;
