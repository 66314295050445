import React from 'react';
import Footer from '../Components/Footer';

const WomenEducation = () => {
    return (
        <>

            <div className='Heading'>
                Home / महिला शिक्षा
            </div>

            <div className="naari">

                <p>महिला शिक्षा के माध्यम से समाज में समानता और प्रगति के नए आयाम स्थापित किए जा सकते हैं।</p>
                <p>महिला शिक्षा का उद्देश्य महिलाओं को सशक्त बनाना, आत्मनिर्भरता बढ़ाना, और उनके जीवन में सुधार लाना है।</p>
                <ul>
                    <li>सरकार द्वारा विशेष छात्रवृत्ति योजनाएं।</li>
                    <li>महिला शिक्षा के लिए निःशुल्क स्कूल और प्रशिक्षण संस्थान।</li>
                    <li>ग्रामीण क्षेत्रों में शिक्षा का प्रचार-प्रसार।</li>
                    <li>डिजिटल शिक्षा और तकनीकी प्रशिक्षण का प्रावधान।</li>
                </ul>
                <p>शिक्षा महिलाओं को अपने अधिकारों और कर्तव्यों के प्रति जागरूक करती है।</p>
            </div>

            <Footer />

        </>
    );
};

export default WomenEducation;
