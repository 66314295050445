import React from 'react';
import '../Pages/head.css';

const Head = () => {
  return (
    <div className="head-container">
      <h1 className="main-heading">सामान्य नारी से आधुनिक नारी तक का सफर</h1>
      <h2 className="sub-heading">बदलाव संभव है |</h2>
    </div>
  );
};

export default Head;
