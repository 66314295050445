import React from 'react';
import Footer from '../Components/Footer';

const DomesticViolenceSolution = () => {
    return (
       <>


<div className='Heading'>
                Home / घरेलू हिंसा समाधान
            </div>


        <div className="naari">
          
            <p>घरेलू हिंसा एक गंभीर सामाजिक समस्या है, जिसके समाधान के लिए विभिन्न पहल की जा रही हैं।</p>
            <p>महिलाओं के अधिकारों की रक्षा के लिए विशेष कानून और सेवाएं उपलब्ध कराई गई हैं।</p>
            <ul>
                <li>घरेलू हिंसा अधिनियम, 2005 का सख्त पालन।</li>
                <li>महिला हेल्पलाइन सेवाएं।</li>
                <li>कानूनी सहायता और परामर्श सेवाएं।</li>
                <li>समाज में जागरूकता अभियान।</li>
            </ul>
            <p>समाज की मानसिकता बदलने और महिलाओं को न्याय दिलाने की दिशा में ये प्रयास महत्वपूर्ण हैं।</p>
        </div>
       
       
       <Footer/>
       </>
    );
};

export default DomesticViolenceSolution;
