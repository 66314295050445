import React from "react";
import "../service.css";
import mahilaShiksha from "./img/siksha.jpg";
import gharaluHinsa from "./img/hinsha.jpg";
import placement from "./img/place.jpg";
import mahilaSuraksha from "./img/surasha.jpg";
import kaushalVikas from "./img/kausal.jpg";

const services = [
  {
    name: "महिला शिक्षा",
    description:
      "महिला शिक्षा के माध्यम से समाज को सशक्त बनाया जाता है। इस योजना का उद्देश्य लड़कियों को शिक्षा का अधिकार दिलाना है, जिससे वे आत्मनिर्भर बन सकें और समाज में अपनी महत्वपूर्ण भूमिका निभा सकें।",
    imgSrc: mahilaShiksha,
  },
  {
    name: "घरेलू हिंसा समाधान",
    description:
      "घरेलू हिंसा पीड़ित महिलाओं के लिए सहायता और समाधान प्रदान किया जाता है। यह सेवा महिलाओं को उनके अधिकारों के बारे में जागरूक करती है और उन्हें एक सुरक्षित वातावरण देने का प्रयास करती है।",
    imgSrc: gharaluHinsa,
  },
  {
    name: "प्लेसमेंट मार्गदर्शन",
    description:
      "यह योजना महिलाओं को रोजगार के अवसर प्रदान करने में मदद करती है। प्लेसमेंट मार्गदर्शन के माध्यम से उन्हें बेहतर करियर विकल्पों की जानकारी दी जाती है और रोजगार के लिए तैयार किया जाता है।",
    imgSrc: placement,
  },
  {
    name: "महिला सुरक्षा",
    description:
      "महिलाओं की सुरक्षा के लिए विशेष प्रावधान और सेवाएँ प्रदान की जाती हैं। इस योजना का उद्देश्य महिलाओं को सुरक्षित और सशक्त महसूस कराना है।",
    imgSrc: mahilaSuraksha,
  },
  {
    name: "कौशल विकास",
    description:
      "महिलाओं के लिए कौशल विकास कार्यक्रम उनकी क्षमता और आत्मनिर्भरता को बढ़ावा देता है। यह योजना उन्हें विभिन्न उद्योगों में रोजगार के लिए सक्षम बनाती है।",
    imgSrc: kaushalVikas,
  },
];

const Services = () => {
  return (
    <div className="services-container">
      <h1 className="services-title">हमारी सेवाएँ</h1>
      <div className="services-grid">
        {services.map((service, index) => (
          <div key={index} className="service-card">
            <img
              src={service.imgSrc}
              alt={service.name}
              className="service-image"
            />
            <h2 className="service-name">{service.name}</h2>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
