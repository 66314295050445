import React, { useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import Footer from '../Components/Footer';

import women from '../shakti/womenimp.jpg'
import sashakti from '../shakti/shashakti.jpg'



const Mahila = () => {
  const [activePage, setActivePage] = useState(1);
  const totalPages = 15;

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  // Render pagination items
  let items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === activePage}
        onClick={() => handlePageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <>
      <div className='Heading'>
        Home / महिला शक्ति
      </div>


      {activePage === 1 && (
        <div className='naari'>
          <h1>Wake Foundation</h1>
          <h2>जब हैं नारी में शक्ति सारी, तो फिर क्यों नारी को कहे बेचारी...</h2>
          <p>महिला सशक्तिकरण क्या है?</p>
          <img src={women} />
          <p>महिला सशक्तीकरण को बेहद आसान शब्दों में परिभाषित किया जा सकता है कि इससे महिलाएं शक्तिशाली बनती है जिससे वह अपने जीवन से जुड़े सभी फैसले स्वयं ले सकती है. और परिवार और समाज में अच्छे से रह सकती है। समाज में उनके वास्तविक अधिकार को प्राप्त करने के लिए उन्हें सक्षम बनाना महिला सशक्तीकरण हैं। इसमें ऐसी ताकत है कि वह समाज और देश में बहुत कुछ बदल सके।</p>
          <p>महिला सशक्तिकरण मतलब महिलाओं की उस क्षमता से है। जिससे उनमें ये योग्यता आ जाती है, जिसमे वे अपने जीवन से जुड़े सभी निर्णय ले सकती है।</p>
          <h3>"यत्र नार्यस्तु पूज्यन्ते रमन्ते तत्र देवताः" - जनहित में जारी</h3>
        </div>
      )}


      {activePage === 2 && (
        <div className='naari'>

          <p>साधारण शब्दों में महिलाओं के सशक्तिकरण का मतलब है कि महिलाओं को अपनी जिंदगी का फैसला करने की स्वतंत्रता देना या उनमें ऐसी क्षमताएं पैदा करना ताकि वे समाज में अपना सही स्थान स्थापित कर सकें।</p>

          <p>8 March को पूरे विश्व में अन्तराष्ट्रीय महिला दिवस / International Women's Day मनाते है। सही मायने में इस महिला दिन का उद्देश्य क्या है? हमें ये समझना होगा देश की तरक्की करनी है तो महिलाओं को सशक्त बनाना होगा।</p>
          <p>क्या है नारी सशक्तिकरण चुनौतियाँ और सम्भावनाएँ।</p>
          <p>हम पुरुष सशक्तिकरण की बजाए केवल महिलाओं के सशक्तिकरण के बारे में ही क्यों बात करते हैं? महिलाओं को क्यों सशक्तिकरण की आवश्यकता है और पुरुषों की क्यों नहीं है? दुनिया की कुल आबादी का लगभग 50% महिलाएं हैं फिर भी समाज के इस बड़े हिस्से को सशक्तिकरण की आवश्यकता क्यों है? महिलाएं अल्पसंख्यक भी नहीं है कि उन्हें किसी प्रकार की विशेष सहायता की आवश्यकता हो। तो यहाँ सवाल यह उठता है कि हम 'महिला सशक्तिकरण' विषय
            पर चर्चा क्यों कर रहे हैं?</p>
          <img src={sashakti} />
          <br /><br />
          <h3>नारी वह वृक्ष है जिस पर रिश्तों की शाखाएं जन्म लेती हैं।</h3>
        </div>
      )}





      {activePage === 3 && (
        <div className='naari'>
          <h1>महिला सशक्तिकरण की जरूरत क्यों ?</h1>

          <p>सशक्तिकरण की आवश्यकता सदियों से महिलाओं का पुरुर्षों द्वारा किए गए। शोषण और भेदभाव से मुक्ति दिलाने के लिए हुई: महिलाओं की आवाज़ को हर तरीके से दबाया जाता है। महिलाएं विभिन्न प्रकार की हिंसा और दुनिया भर में पुरुषों ‌द्वारा किए जा रहे भेदभावपूर्ण व्यवहारों का लक्ष्य है। भारत भी अछूता नहीं है।</p>

          <p>भारत एक जटिल देश है। यहाँ संदियों से विभिन्न प्रकार की रीति-रिवाजो, परंपराओं और प्रथाओं का विकास हुआ है। ये रीति-रिवाज और परंपराएं, कुछ अच्छी और कुछ बुरी, हमारे समाज की सामूहिक चेतना का एक हिस्सा बन गई हैं। हम महिलाओं को देवी मान उनकी पूजा करते हैं। हम अपनी मां, बेटियों, बहनों, पत्नियों और अन्य महिला रिश्तेदारों या दोस्तों को भी बहुत महत्व देते हैं, लेकिन साथ ही भारतीय अपने घरों के अंदर और अपने घरों के बाहर महिलाओं से किए बुरे व्यवहार के लिए भी प्रसिद्ध हैं।</p>
          <p>हर धर्म हमें महिलाओं के सम्मान और शिष्टता के साथ व्यवहार करना सिखाता है। आज के आधुनिक में समाज की सोच इतनी विकसित हो गई है कि महिलाओं के खिलाफ शारीरिक और मानसिक दोनों प्रकार की कुरीतियों और प्रथाएँ आदर्श बन गई हैं। जैसे सतीप्रथा, दहेज प्रथा, परदा प्रथा, भ्रूण हत्या, पत्नी को जलाना, यौन हिंसा, कार्यस्थल पर यौन उत्पीडन, घरेलू हिंसा और अन्य विभिन्न प्रकार के भेदभावपूर्ण व्यवहारः ऐसे सभी कार्यों में शारीरिक और मानसिक तत्व शामिल होते हैं</p>
          <p>महिलाओं के खिलाफ अपराध या अत्याचार अभी भी बढ़ रहे हैं। इनसे निपटने के लिए समाज में पुरानी सोच वाले लोगों के मन को सामाजिक योजनाओं और संवेदीकरण कार्यक्रमों के माध्यम से बदलना होगा।</p>

          <h3>नारी है तो जीवन है।</h3>
        </div>
      )}


      {activePage === 4 && (
        <div className='naari'>


          <p>इसलिए महिला सशक्तिकरण की सोच न केवल महिलाओं की ताकत और कौशल को उनके दुखदायी स्थिति से ऊपर उठाने पर केंद्रित करती है, बल्कि साथ ही यह पुरुषों को महिलाओं के संबंध में शिक्षित करने और महिलाओं के प्रति बराबरी के साथ सम्मान और कर्तव्य की भावना पैदा करने की आवश्यकता पर जोर देती है।</p>
          <p><b>संयुक्त राष्ट्र के अनुसार महिलाओं के सशक्तिकरण में मुख्य रूप से पांच कारण</b></p>

          <p>महिलाओं में आत्म-मूल्य की भावना। महिलाओं को उनके अधिकार और उनको निर्धारित करने की स्वतंत्रता। समान अवसर और सभी प्रकार के संसाधनों तक पहुंच प्राप्त करने का महिलाओं का अधिकार। घर के अंदर और बाहर अपने स्वयं के जीवन को विनियमित करने और नियंत्रित करने का महिलाओं को अधिकार। अधिक सामाजिक और आर्थिक व्यवस्था बनाने में योगदान करने की महिलाओं की क्षमता।</p>

          <p>भारत में महिला सशक्तिकरण</p>

          <p>प्राचीन से लेकर आधुनिक काल तक महिला की स्थिति-सामाजिक, राजनीतिक और आर्थिक रूप से समान नहीं रही है। महिलाओं के हालातों में कई बार बदलाव हुए हैं। प्राचीन भारत में महिलाओं को पुरुषों के समान दर्जा प्राप्त था, शुरुआती वैदिक काल में वे बहुत ही शिक्षित थी। हमारे प्राचीन ग्रंथों में मैत्रयी जैसी महिला संती के उदहारण भी हैं।</p>

          <p>सभी प्रकार की भेदभावपूर्ण प्रथाएँ बाल विवाह, देवदासी प्रणाली, नगर वधु, सती प्रथा आदि से शुरू हुई हैं। महिलाओं के सामाजिक-राजनीतिक अधिकारों को कम कर दिया गया और इससे वे परिवार के पुरुष सदस्यों पर पूरी तरह से निर्भर हो गई।</p>

          <h2>महिलायें इस समाज की असल शिल्पकार हैं।  - हैरियट बीचर स्टोव </h2>


        </div>
      )}



      {activePage === 5 && (
        <div className='naari'>


          <p>शिक्षा के अधिकार, काम करने के अधिकार और खुद के लिए फैसला करने के अधिकार उनसे छीन लिए गए। मध्ययुगीन काल के दौरान भारत में मुस्लिम शासकों के आगमन के साथ महिलाओं की हालत और भी खराब हुई। ब्रिटिश शासन अपने साथ पश्चिमी विचार भी देश में लेकर आया।</p>

          <p>राजा राम मोहन रॉय जैसे कुछ प्रबुद्ध भारतीयों ने महिलाओं के खिलाफ प्रचलित भेदभाव संबंधी प्रथाओं पर सवाल खड़ा किया। अपने निरंतर प्रयासों के माध्यम से ब्रिटिशों को सती प्रथा को समाप्त करने के लिए मजबूर किया गया।</p>

          <p>इसी तरह ईश्वर चंद्र वि‌द्यासागर, स्वामी विवेकानंद, आचार्य विनोबा भावे आदि जैसे कई अन्य सामाजिक सुधारक ने भारत में महिलाओं के उत्थान के लिए काम किया। उदाहरण के लिए 1856 के विधवा पुनर्विवाह अधिनियम विधवाओं की शर्तों में सुधार ईश्वर चंद्र वि‌द्यासागर के आंदोलन का परिणाम था।</p>

          <p>स्वतंत्रता आंदोलन के संघर्ष के लगभग सभी नेताओं का मानना था कि स्वतंत्र भारत में महिलाओं को समान दर्जा दिया जाना चाहिए और सभी प्रकार की भेदभावपूर्ण प्रथाओं को रोका जाना चाहिए और ऐसा होने के लिए भारत के संविधान में ऐसे प्रावधानों को शामिल करना सबसे उपयुक्त माना जाता था। जो पुरानी शोषण प्रथाओं और परंपराओं को दूर करने में सहायता करेगा और ऐसे प्रावधान भी करेगा जो महिलाओं की सामाजिक, आर्थिक और राजनीतिक रूप से सशक्त बनाने में मदद करेंगे।</p>

          <p>संविधान और महिला सशक्तिकरण</p>

          <p>भारत का संविधान दुनिया में सबसे अच्छा समानता प्रदान करने वाले दस्तावेजों में से एक है। संविधान के विभिन्न लेख सामाजिक, राजनीतिक और आर्थिक रूप से महिलाओं के पुरुषों के समान अधिकारों की रक्षा करते हैं।</p>

          <h2>ईट-पत्थरों से ती मकान बनते हैं। उसे घर तो बस एक औरत ही बना सकती है।</h2>



        </div>
      )}



      {activePage === 6 && (
        <div className='naari'>


          <p>महिलाओं के मानवाधिकारों को सुरक्षित रखने के लिए संविधान की प्रस्तावना, मौलिक अधिकार, डीपीएसपी और अन्य संवैधानिक प्रावधान कई तरह के विशेष सुरक्षा उपाय प्रदान करते हैं।</p>

          <p><strong>प्रस्तावना :</strong> भारत के संविधान की प्रस्तावना न्याय, सामाजिक, आर्थिक और राजनीतिक आश्वासन देती है। इसके अलावा यह व्यक्ति की स्थिति, बराबरी के अवसर और गरिमा की समानता भी प्रदान करती है।</p>

          <p><strong>मौलिक अधिकार :</strong> हमारे संविधान में निहित मूलभूत अधिकारों में महिला सशक्तिकरण की नीति अच्छी तरह से विकसित हुई है।</p>

          <ul>
            <li>अनुच्छेद 14 महिलाओं को समानता का अधिकार सुनिश्चित करता है।</li>
            <li>अनुच्छेद 15 (1) विशेष रूप से लिंग के आधार पर किए जाने वाले भेदभाव पर प्रतिबंध लगाता है।</li>
            <li>अनुच्छेद 15 (3) राज्य को महिलाओं के पक्ष में सकारात्मक कार्रवाई करने का अधिकार देता है।</li>
            <li>अनुच्छेद 16 किसी भी कार्यालय में रोजगार या नियुक्ति से संबंधित मामलों में सभी नागरिकों के लिए अवसर की समानता प्रदान करता है।</li>
          </ul>

          <p>ये अधिकार, मौलिक अधिकार अदालत में न्यायसंगत हैं और सरकार उसी का पालन करने के लिए बाध्य है।</p>

          <p><strong>महिला सशक्तिकरण के लिए विशिष्ट कानून:</strong></p>

          <p>यहां कुछ विशिष्ट कानूनों की सूची दी गई है जो संसद ‌द्वारा महिलाओं के सशक्तिकरण के संवैधानिक दायित्व को पूरा करने के लिए लागू की गई थी:</p>

          <ul>
            <li>समान पारिश्रमिक अधिनियम, 1976</li>
            <li>दहेज निषेध अधिनियम, 1961</li>
            <li>अनैतिक यातायात (रोकथाम) अधिनियम, 1956</li>
            <li>मातृत्व लाभ अधिनियम, 1961</li>
            <li>गर्भावस्था अधिनियम का अंत, 1971</li>
          </ul>

          <h2>कर्तव्यों संग नारी भर रही है उड़ान, ना कोई शिकायत ना कोई धकान। जनहित में जारी।</h2>




        </div>
      )}





      {activePage === 7 && (
        <div className='naari'>


          <p>सती आयोग (रोकथाम) अधिनियम, 1987</p>
          <p>बाल विवाह अधिनियम, 2006 का निषेध</p>
          <p>पी-कॉन्सेशेशन एंड प्री-नेटाल डायग्नॉस्टिक टेक्निक्स (विनियमन और निवारण) अधिनियम, 1994</p>

          <p>उपर्युक्त और कई अन्य कानून हैं जो न केवल महिलाओं को विशिष्ट कानूनी अधिकार प्रदान करते हैं बल्कि उन्हें सुरक्षा और सशक्तिकरण की भावना भी प्रदान करते हैं।</p>

          <p><strong>महिला सशक्तिकरण के लिए अंतर्राष्ट्रीय प्रतिबद्धताएं:</strong></p>
          <p>भारत विभिन्न अंतर्राष्ट्रीय सम्मेलनों और संधियों से जुड़ा हुआ है, जो महिलाओं के समान अधिकारों को सुरक्षित करने के लिए प्रतिबद्ध हैं।</p>

          <ul>
            <li>मैक्सिको प्लान ऑफ एक्शन (1975)</li>
            <li>नैरोबी फॉरवर्ड लुकिंग स्ट्रैटजीज (1985)</li>
            <li>बीजिंग घोषणापत्र और प्लैटफॉर्म फ्रॉर एक्शन (1995)</li>
            <li>यूएनजीए सत्र द्वारा अपनाया गया "बीजिंग घोषणापत्र" - 21वीं शताब्दी के लिए लैंगिक समानता, विकास, शांति और आगे की कार्रवाइयों के लिए</li>
          </ul>

          <p>इन सभी को भारत द्वारा उचित अनुवर्ती कार्रवाई के लिए पूर्ण रूप से समर्थन दिया गया है।</p>

          <p>इन सभी विभिन्न राष्ट्रीय और अंतर्राष्ट्रीय प्रतिबद्धताओं, कानूनों और नीतियों के बावजूद महिलाओं की स्थिति में अभी भी संतोषजनक रूप से सुधार नहीं हुआ है। महिलाओं से संबंधित विभिन्न समस्याएं अभी भी समाज में मौजूद हैं। महिलाओं का शोषण बढ़ रहा है, दहेज प्रथा अभी भी प्रचलित है, महिलाओं के खिलाफ घरेलू हिंसा का अभ्यास किया जाता है, कार्यस्थल पर यौन उत्पीड़न और महिलाओं के खिलाफ अन्य जघन्य यौन अपराध बढ़ रहे हैं। महिलाओं की आर्थिक और सामाजिक स्थिति में महत्वपूर्ण तरीके से सुधार हुआ है लेकिन यह परिवर्तन केवल महानगरों या शहरी क्षेत्रों में ही दिखाई देता है।</p>

          <h2>एक औरत आधे आसमान को पकड़ कर रख सकती है। - चीनी कहावत</h2>


        </div>
      )}



      {activePage === 8 && (
        <div className='naari'>

          <h1><strong>महिला सशक्तिकरण के लिए सरकारी नीतियां और योजनाएं:</strong></h1>
          <p>महिलाओं का जो भी सुधार और सशक्तिकरण हुआ है वह विशेष रूप से उनके अपने स्वयं के प्रयासों और संघर्ष के कारण हुआ है। हालांकि, उनके प्रयासों में सहायता करने के लिए सरकारी योजनाएं भी हैं।</p>

          <p>वर्ष 2001 में भारत सरकार ने महिला सशक्तिकरण के लिए एक राष्ट्रीय नीति का शुभारंभ किया। नीति के विशिष्ट उद्देश्य निम्नलिखित हैं:</p>

          <ul>
            <li>महिलाओं के पूर्ण विकास हेतु सकारात्मक आर्थिक और सामाजिक नौतिकाओं के माध्यम से एक ऐसा पर्यावरण बनाना जो उन्हें अपनी पूरी क्षमता का पता लगाने में मदद करे।</li>
            <li>सभी राजनैतिक, आर्थिक, सामाजिक, सांस्कृतिक और नागरिक क्षेत्रों में पुरुषों के समान आधार पर महिलाओं को सभी मानवाधिकार और मौलिक स्वतंत्रता का आनंद लेने के लिए एक समान अवसर प्रदान करना।</li>
            <li>राष्ट्र के सामाजिक, राजनीतिक और आर्थिक जीवन में महिलाओं की भागीदारी और निर्णय लेने की प्रक्रिया में समान पहुंच प्रदान करना।</li>
            <li>स्वास्थ्य देखभाल, सभी स्तरों पर गुणवत्ता की शिक्षा, करियर और व्यावसायिक मार्गदर्शन, रोजगार, समान पारिश्रमिक, व्यावसायिक स्वास्थ्य और सुरक्षा, सामाजिक सुरक्षा, और सार्वजनिक जीवन के लिए महिलाओं को समान अवसर प्रदान करना।</li>
            <li>महिलाओं के खिलाफ सभी प्रकार के भेदभाव को समाप्त करने के उद्देश्य से कानूनी प्रणाली को सुदृढ़ बनाना।</li>
            <li>सक्रिय भागीदारी और पुरुषों व महिलाओं दोनों की सहयोगी भागीदारी से सामाजिक व्यवहार और समुदाय की प्रथाओं में सुधार करना।</li>
            <li>भेदभाव, महिलाओं और लड़कियों के खिलाफ हिंसा के सभी प्रकारों का उन्मूलन करना।</li>
          </ul>

          <p>नहीं सहना है अब किसी का अत्याचार, महिला सशक्तिकरण का यही है मुख्य विचार।</p>

          <p>जनहित में जारी</p>


        </div>
      )}




      {activePage === 9 && (
        <div className='naari'>

          <p><strong>महिला और बाल विकास मंत्रालय:</strong> महिलाओं के कल्याण, विकास और सशक्तिकरण से संबंधित सभी मामलों के लिए यह मंत्रालय नोडल एजेंसी है। इसकी विभिन्न योजनाएं जैसे स्वशक्ति, स्वयं सिद्धा, स्टेप, और स्वावलंबन आदि महिलाओं को आर्थिक रूप से सशक्त बनाने में सक्षम हैं।</p>

          <p><strong>महिलाओं की उपलब्धियां:</strong></p>
          <p>पिछले कुछ सालों में देश में महिलाओं की स्थिति में काफी सुधार हुआ है। महिला सशक्तिकरण पर खास जोर दिया गया है। यहां कुछ प्रमुख उपलब्धियां दी गई हैं:</p>

          <ul>
            <li><strong>1848:</strong> सावित्रीबाई फुले ने अपने पति ज्योतिराव फुले के साथ पुणे में महिलाओं के लिए स्कूल खोली। इस प्रकार, सावित्रीबाई फुले भारत की पहली महिला शिक्षिका बनीं।</li>
            <li><strong>1898:</strong> भगिनी निवेदिता ने महिलाओं के लिए स्कूल की स्थापना की।</li>
            <li><strong>1916:</strong> 2 जून 1916 को, पहली महिला यूनिवर्सिटी (SNDT) की स्थापना सामाजिक सुधारक धोंडो केशव कर्वे ने केवल पाँच विद्यार्थियों के साथ मिलकर की।</li>
            <li><strong>1917:</strong> भारतीय राष्ट्रीय कांग्रेस की पहली महिला अध्यक्षा एनी बेसेंट बनीं।</li>
            <li><strong>1925:</strong> भारत में जन्मी सरोजिनी नायडू भारतीय राष्ट्रीय कांग्रेस की पहली महिला अध्यक्षा बनीं।</li>
            <li><strong>1927:</strong> ऑल इंडिया विमेन कॉन्फ्रेंस (All India Women's Conference) की स्थापना की गई।</li>
          </ul>

          <p>धन्य हो तुम, माँ सीता, तुमने नारी का मन जीता।</p>

          <p>जनहित में जारी</p>


        </div>
      )}


      {activePage === 10 && (
        <div className='naari'>

          <ul>
            <li><strong>1951:</strong> प्रेम माथुर डेक्कन एयरवेज की भारत की पहली कमर्शियल महिला पायलट बनीं।</li>
            <li><strong>1953:</strong> विजया लक्ष्मी पंडित यूनाइटेड नेशन जनरल असेंबली की पहली महिला अध्यक्षा बनीं।</li>
            <li><strong>1959:</strong> अन्ना चंडी केरल हाई कोर्ट की पहली महिला जज बनीं।</li>
            <li><strong>1963:</strong> सुचेता कृपलानी उत्तर प्रदेश की मुख्यमंत्री बनीं, जो किसी भी भारतीय राज्य की पहली महिला मुख्यमंत्री थीं।</li>
            <li><strong>1966:</strong> कमलादेवी चट्टोपाध्याय को कम्युनिटी लीडरशिप के लिए रोमन मैगसेसे पुरस्कार से सम्मानित किया गया।</li>
            <li><strong>1966:</strong> इंदिरा गांधी भारत की पहली महिला प्रधानमंत्री बनीं।</li>
            <li><strong>1970:</strong> कमलजीत संधू एशियाई खेलों में गोल्ड जीतने वाली पहली भारतीय महिला बनीं।</li>
            <li><strong>1972:</strong> किरण बेदी भारतीय पुलिस बल में शामिल होने वाली पहली महिला बनीं।</li>
            <li><strong>1979:</strong> मदर टेरेसा को नोबेल शांति पुरस्कार से सम्मानित किया गया, इसे हासिल करने वाली वह पहली भारतीय महिला नागरिक थीं।</li>
            <li><strong>1984:</strong> 23 मई को बचेंद्री पाल माउंट एवरेस्ट पर चढ़ने वाली पहली भारतीय महिला बनीं।</li>
            <li><strong>1986:</strong> सुरेखा यादव भारत और एशिया की पहली महिला लोको-पायलट (रेलवे ड्राइवर) बनीं।</li>
          </ul>

          <p>ओ महिलाएं, अपनी शक्ति को पहचानो, सफलता तुम्हारे कदम चूमेगी।</p>


        </div>
      )}


      {activePage === 11 && (
        <div className='naari'>

          <ul>
            <li><strong>1989:</strong> न्यायमूर्ति एम. फातिमा बीवी भारतीय सुप्रीम कोर्ट की पहली महिला जज बनीं।</li>
            <li><strong>1992:</strong> प्रिया झिंगन इंडियन आर्मी में शामिल होने वाली पहली महिला कैडेट बनीं।</li>
            <li><strong>1999:</strong> 31 अक्टूबर को सोनिया गांधी भारतीय विपक्षी दल की पहली महिला नेता बनीं।</li>
            <li><strong>2007:</strong> 25 जुलाई को प्रतिभा पाटिल भारत की पहली महिला राष्ट्रपति बनीं।</li>
          </ul>

          <h3>द्रौपदी मुर्मू: आधी आबादी के लिए गौरवशाली उपलब्धि</h3>
          <p>2022 में भारत को पहली आदिवासी महिला राष्ट्रपति के रूप में द्रौपदी मुर्मू मिलीं। यह न केवल भारत के समावेशी लोकतंत्र की शानदार उपलब्धि है बल्कि महिला सशक्तिकरण का भी प्रतीक है। 25 जुलाई 2022 को उन्होंने देश के 15वें राष्ट्रपति के रूप में कार्यभार संभाला, और उनके व्यक्तित्व और सकारात्मक सोच ने देश के नागरिकों को प्रेरित किया।</p>

          <blockquote>
            "एक महिला जब खुल कर बोलती है, सही मायने में वह एक सशक्त महिला है।" -- मेलिंडा गेट्स
          </blockquote>



        </div>
      )}


      {activePage === 12 && (
        <div className='naari'>

          <h3>खेलों  की दुनिया में देश का बढ़ाया गौरव</h3>
          <p>वर्ष 2022 में महिलाओं ने लगातार देश का गौरव बढ़ाया। जनवरी में विजयश्री की जो शुरुआत हुई, वह सिलसिला आगे भी जारी रहा। पीवी सिंधु ने पूरे साल अपने शानदार खेल से देश का नाम रोशन किया, जिसकी शुरुआत सैयद मोदी इंटरनेशनल बैडमिंटन टूर्नामेंट 2022 में जीत से हुई।</p>

          <blockquote>
            "जो आदर्श नारी होती है, वही आदर्श पत्नी भी हो सकती है। औरत के हाथ में बड़ी बरकत होती हैं।" -- प्रेमचंद
          </blockquote>

        </div>
      )}


      
      {activePage === 13 && (
        <div className='naari'>

          <h3>आमतौर पर क्रिकेट में लप्पेबाजी करना</h3>
          <p>आमतौर पर क्रिकेट में लप्पेबाजी करना यानी लंबे-लंबे शॉट लगाना, पुरुष खिलाड़ियों का कार्य माना जाता है, लेकिन इस साल महज 26 गेंदों में 50 रन बनाकर रिचा घोष ने लप्पेबाजों की सूची में खुद को दर्ज कराया। वहीं दूसरी ओर, मिताली राज, जो कि भारतीय महिला क्रिकेट की सालों से पहचान रही हैं, ने इस साल एकदिवसीय क्रिकेट विश्व कप में हिस्सा लिया।</p>

          <p>झूलन गोस्वामी इस साल एकदिवसीय महिला क्रिकेट विश्व कप में संयुक्त रूप से सर्वाधिक विकेट लेने वाली गेंदबाज बनकर उभरी। महिला वेटलिफ्टर मीरा बाई चानू ने इस साल एक बार फिर देशवासियों को गर्व के क्षण दिए, जब उन्होंने सिंगापुर वेटलिफ्टिंग इंटरनेशनल चैंपियनशिप में स्वर्ण पदक जीता।</p>

          <p>वहीं निकहत जरीन और नीलू ने स्ट्रैएजा नेमोरियल बॉक्सिंग टूर्नामेंट में स्वर्ण पदक जीता। प्रियंका नुटक्की इस साल आरत की 23वीं महिला रॉड मास्टर बनीं। प्रियंका मोहिते ने 8,000 मीटर से अधिक ऊंचाई वाली पांच पर्वत चोटियों पर चढ़ने वाली देश की पहली महिला बनने का गौरव प्राप्त किया।</p>

          <p>पीटी उषा का भारतीय ओलंपिक संघ की पहली महिला अध्यक्ष बनना भी देश की आधी आबादी के लिए गर्व की बात है।</p>

          <h4>साल की शुरुआत में ही लहराया परचम</h4>
          <p>देश की आधी आबादी के लिए वर्ष 2022 का आगाज ही शानदार रहा। सबसे पहले 1 जनवरी 2022 को निकिता सोकल ने निसेज इंडिया गॅलेक्सी 2021 का खिताब जीतकर महिलाओं के खाते में गौरवशाली उपलब्धि जोड़ी।</p>

          <p>स्मृति मंधाना को आईसीसी वूमेन क्रिकेटर ऑफ द ईयर का पुरस्कार मिला।</p>

          <h4>फिल्म और साहित्य में मिला सम्मान</h4>
          <p>फिल्म और साहित्य के क्षेत्र में भी इस साल आधी आबादी के नाम का डंका बजा। जहां फिल्म अभिनेत्री आशा पारेख को 52वे दादा साहब फाल्के पुरस्कार से सम्मानित किया गया।</p>

          <blockquote>
            "नारी के जीवन का संतोष ही स्वर्ण-श्री का प्रतीक है।" -- डॉ. रामकुमार वर्मा
          </blockquote>

        </div>
      )}

      {activePage === 14 && (
        <div className='naari'>

          <h4>वर्ष 2022 में हिंदी लेखिका की सफलता</h4>
          <p>वहीं वर्ष 2022 में साहित्य के विश्व आकाश में हिंदी लेखिका गीतांजलि श्री ने अपने उपन्यास "टॉम्ब ऑफ सैंड" (रेत समाधि) के लिए अंतरराष्ट्रीय बुकर पुरस्कार हासिल किया। जब वह इसके लिए नामित हुई, और जब उन्होंने इसे हासिल किया, तब यह एक बड़ी उपलब्धि बन गई।</p>

          <p>उधर भारतीय लेखिका मीना कंडासामी ने इंटरनेशनल 'हर्मन केस्टन पुरस्कार 2022' भी जीता।</p>

          <h4>विभिन्न क्षेत्रों में स्थापित किए कीर्तिमान</h4>
          <p>बिना पढ़े-लिखे होने के बावजूद मध्य प्रदेश के मंडला जिले के बरबसपुर गांव की दुर्गा बाई व्याम को कला के क्षेत्र में महत्वपूर्ण योगदान के लिए पद्मश्री से नवाजा गया।</p>

          <h4>महिला सशक्तिकरण में सफलता</h4>
          <p>भारत सरकार की सर्वाधिक लोकप्रिय योजनाओं में से एक 'बेटी बचाओ, बेटी पढ़ाओ' की इसी साल ब्रांड अंबेस्डर तनिष्का कोटिया और रिद्धिका कोटिया बनीं। रोलू खंडूरी, उत्तराखंड राज्य विधानसभा की पहली महिला स्पीकर बनीं। साथ ही रुचिरा कांबोज को यूएन में भारत के स्थायी प्रतिनिधि के रूप में चुना गया।</p>

          <p>इस वर्ष भारत के शिक्षा मंत्रालय ने यूनीसेफ के सहयोग से 'कन्या शिक्षा प्रवेश उत्सव' अभियान शुरू किया, और देश के एमएसएमई मंत्रालय ने महिलाओं के लिए 'समर्थ' नाम का विशेष उद्यमिता संवर्धन अभियान शुरू किया।</p>

          <h4>भारत में और विश्व में भारतीय महिलाओं की सफलता</h4>
          <p>इस तरह देखें तो भारत में और साथ ही पूरे विश्व में भी भारतीय महिलाओं की सफलता का सफर भले धीमी गति से लेकिन लगातार आगे बढ़ता रहा। बीत रहा वर्ष 2022 भी इसका गवाह बना।</p>

          <h4>समाज में महिलाओं की भूमिका</h4>
          <p>हम खुद को आधुनिक कहने लगे हैं, लेकिन सच यह है कि मॉर्डनाइजेशन सिर्फ हमारे पहनावे में आया है, लेकिन विचारों से हमारा समाज आज भी पिछड़ा हुआ है। आज महिलाएं एक कुशल गृहणी से लेकर एक सफल व्यावसायी की भूमिका बेहतर तरीके से निभा रही हैं। नई पीढ़ी की महिलाएं तो स्वयं को पुरुषों से बेहतर साबित करने का एक भी मौका गंवाना नहीं चाहती। लेकिन गांव और शहर की इस दूरी को मिटाना जरूरी है।</p>

          <blockquote>
            "जहाँ नारी का सम्मान होता है, वहाँ देवता भी प्रसन्न रहते हैं।" – मनु
          </blockquote>


        </div>
      )}


      {activePage === 15 && (
        <div className='naari'>

          <h4>महिलाओं की समस्याओं और समाज में बदलाव की आवश्यकता</h4>
          <p>हमें महिलाओं की समस्याओं के बारे में समाज के पुरुष सदस्यों को शिक्षित और संवेदित करना है और उनके बीच एकजुटता और समानता की भावना पैदा करने की आवश्यकता है ताकि वे अपने भेदभावपूर्ण व्यवहारों को कमजोर वर्ग की ओर रोक दें। सबसे पहले हमारे घरों से सभी प्रयास शुरू होने चाहिए, जहां हमें किसी भी भेदभाव के बिना शिक्षा, स्वास्थ्य, पोषण और निर्णय लेने के समान अवसर प्रदान करके हमारे परिवार के महिला सदस्यों को सशक्त करना चाहिए।</p>

          <h4>महिलाओं के प्रति सम्मान और समाज की जिम्मेदारी</h4>
          <blockquote>
            "मां लक्ष्मी, माँ सरस्वती की पूजा करने का कोई मतलब नहीं, या फिर दिन भर उपवास रखने का कोई लाभ नहीं, अगर बारी की इज्जत नहीं करते तो सब व्यर्थ है।"
          </blockquote>

          <p>भारत शक्तिशाली राष्ट्र तभी बन सकता है जब यह वास्तव में अपनी महिलाओं को शक्ति देता है।</p>

          <h4>नारी का महत्व और समाज की मानसिकता</h4>
          <blockquote>
            "नारी नर से भारी हैं फिर क्यों में साचारी है। नारी से ही जन्मा है और नारी को रौंदा है। अबला समझ के तूने नारी शांति की प्रतिमा है, इसे उच्च पद से नीचे गिराना केवल जंगलीपन है।" – रफोडियसभारतीय
          </blockquote>

          <p>भारतीय समाज में सच में महिला सशक्तिकरण लाने के लिए महिलाओं के खिलाफ बुरी प्रथाओं के मुख्य कारणों को समझना और उन्हें हटाना होगा। यह प्रथाएँ समाज की पितृसत्तात्मक और पुरुष प्रभाव युक्त व्यवस्था से उत्पन्न होती हैं। परिवार ही समाज की इकाई है। जब तक पुरुष प्रधान सदस्य माँ, बहन, पत्नी, बेटी को प्रत्येक क्षेत्र में समान अधिकार नहीं देंगे, तब तक समाज में स्त्रियों की स्थिति बेहतर नहीं हो सकती।</p>

          <h4>पुरानी सोच और कानूनी बदलाव की आवश्यकता</h4>
          <p>जरूरत है कि हम महिलाओं के खिलाफ पुरानी सोच को बदलें और संवैधानिक और कानूनी प्रावधानों में भी बदलाव लाएं। जिस तरह से भारत सबसे तेजी से आर्थिक तरक्की प्राप्त करने वाले देशों में शुमार हुआ है, उसे देखते हुए निकट भविष्य में भारत को महिला सशक्तिकरण के लक्ष्य को प्राप्त करने पर भी ध्यान केंद्रित करने की आवश्यकता है।</p>

          <h4>महिलाओं का सम्मान और महात्मा गांधी का दृष्टिकोण</h4>
          <blockquote>
            "नारीला कहना उसका अपमान करना है।" – महात्मा गांधी
          </blockquote>

          <p>जनहित में जारी</p>



        </div>
      )}



      <div className="d-flex justify-content-center my-4 pagination-container">
        <Pagination size="lg">{items}</Pagination>
      </div>

      <Footer />
    </>
  );
};

export default Mahila;
